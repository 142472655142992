
// toastr fix
#toast-container {
    z-index: 99999;
}

.toast-top-center {
    position: fixed!important;
    top: 1em;
    margin: 0 auto;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
}

.form-control-feedback {
    font-size: 0.9rem !important;
    font-weight: 300;
}

.form-control.ng-invalid:not(.ng-pristine) {
    border-color: #dc143c!important;
}

// custom badges
.badge-scroll-brown {
	color: #fff;
	background-color: #6b4416;
}

.badge-scroll-red {
	color: #fff;
	background-color: #bf4343;
}

.badge-scroll-orange {
	color: #fff;
	background-color: #d28a18;
}

.badge-scroll-gray {
	color: #fff;
	background-color: #a4a5a8;
}

.badge-scroll-purple {
	color: #fff;
	background-color: #7724ac;
}

.badge-scroll-green {
	color: #fff;
	background-color: #428d25;
}

// utilities

.ff-roboto {
    font-family: 'Roboto', sans-serif !important;
}

.ff-poppins {
    font-family: 'Poppins', sans-serif !important;
}

.fs-75 {
    font-size: 0.90rem !important;
}

// scroll specific 

table .table-small {

	tr, th, td {
		
		&:first-child {
			padding-left: 1em;
		}

		&:last-child {
			padding-right: 1em;
		}

	}

    font-family: 'Roboto', 'Poppins', Helvetica, "sans-serif";
    font-weight: 300!important;
    font-size: 0.50em!important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-justify {
    text-align: justify;
}

.w-15px {
    width: 15px;
    max-width: 15px;
}

.w-35 {
    width: 35%;
}

.w-65 {
    width: 65%;
}

// collapse

.collapsed {
    max-height: 0px!important;
    height: 0px!important;
}

.hide {
    display: none!important;
}

.separator-vertical {
    border-right: 1px solid #cdcdcd;
    margin-left: 2px;
    margin-right: 2px;
}