/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
@import "./assets/plugins/keenicons/duotone/style.css";
@import "./assets/plugins/keenicons/outline/style.css";
@import "./assets/plugins/keenicons/solid/style.css";

@import "./assets/sass/_scroll/init";

spirit-context-menu {
    width: 200px;
    background: #2d2d2d;
    color: #fff;
    padding: 15px;
    border-radius: 5px;
}

spirit-issue-content figure.image {
    max-width: 250px!important;
}

