.node-content-wrapper::before {
    border-bottom: 1px dotted #67B2DD;
    border-left: 1px dotted #67B2DD;
    height: 28px;
    top: -17px;
    width: 20px;
    left: -28px;
}
  
.tree-children::after {
    border-left: 1px dotted #67B2DD;
    height: 100%;
    top: -15px;
    left: -15px;
}