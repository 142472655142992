app-header {
    flex-wrap: nowrap;
    justify-content: flex-end;
    transition: left .3s ease;
    z-index: 100;
    position: fixed!important;
    box-shadow: var(--bs-header-box-shadow);
    background-color: var(--bs-body-bg)!important; /* var(--bs-app-header-base-bg-color); */
    border-bottom: var(--bs-header-border-bottom);
    left: 0;
    right: 0;
    top: 0;
    opacity: 1;
}

app-topbar {
    background-color: var(--bs-header-bg-color);
    opacity: 1;
    z-index: 150;
}

.ck.ck-editor__editable_inline:not(:focus) {
    border: 1px #cdcdcd solid;
}

app-sidebar {
    .app-sidebar-logo-default {
        max-width: 100px;
        width: 100px;
    }
}

.scr-wrapper {
    margin-top: 75px!important;
}

/* Scroll theme colors, based on bs-theme */

[data-bs-theme=light] {
    --scr-ticket-item-link: #242424;
    --scr-ticket-item-link-hover: #575757;
    --scr-dashed-row: #bfc0ca;
    --scr-bg-gray: #cdcdcd;
    --scr-fg-gray: #000;
}

[data-bs-theme=dark] {
    --scr-ticket-item-link: #777a94;
    --scr-ticket-item-link-hover: #9699b1;
    --scr-dashed-row: #26272f;
    --scr-bg-gray: #1f212a;
    --scr-fg-gray: #fff;
}

.badge-light-scroll-gray {
    color: var(--scr-fg-gray);
    background-color: var(--scr-bg-gray);
}

span.row-dashed, div.row-dashed, a.row-dasged {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--scr-dashed-row);
}

a.menu-link, a.menu-link:visited, a.ticket-item, a.ticket-item:visited {
    color: var(--scr-ticket-item-link)!important;
    text-decoration: none!important;
}

a.ticket-item:hover, a.menu-link:hover {
    color: var(--scr-ticket-item-link-hover)!important;
}
